import './bootstrap';
// resources/js/app.js
import 'flowbite';
// import ApexCharts from 'apexcharts';
import { showToast } from './toasts';
import { Datepicker } from 'flowbite-datepicker';
function loadNotificationsModule() {
    const userIdMeta = document.querySelector('meta[name="user-id"]');
    if (userIdMeta && userIdMeta.content) {
        import('./notifications')
            .then(module => {
                console.log('Notifications module loaded');
                // Hier können Sie zusätzliche Initialisierungen durchführen, falls nötig
            })
            .catch(error => console.error('Error loading notifications module:', error));
    }
}

function initDatepickers() {
    const datepickerElements = document.querySelectorAll('[datepicker]');
    datepickerElements.forEach(el => {
        new Datepicker(el, {
            // Globale Optionen hier
            format: 'yyyy-mm-dd',
            autohide: true
        });
    });
}

function initLazyLoading() {
    const lazyImages = document.querySelectorAll(".lazy:not(.lazy-loaded)");
    const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                const img = entry.target;
                img.src = img.getAttribute("data-src");
                img.classList.remove("lazy");
                img.classList.add("lazy-loaded");
                observer.unobserve(img);
            }
        });
    });
    lazyImages.forEach(img => {
        observer.observe(img);
    });
}

const observer = new IntersectionObserver((entries) => {
    entries.forEach(entry => {
        if (entry.isIntersecting) {
            const tooltipTrigger = entry.target;
            new Tooltip(tooltipTrigger);
            observer.unobserve(tooltipTrigger);
        }
    });
});

document.querySelectorAll('[data-tooltip-target]').forEach(tooltipTrigger => {
    observer.observe(tooltipTrigger);
});
export function initializePopovers() {
    const popoverTriggers = document.querySelectorAll('[data-popover-target]');
    popoverTriggers.forEach(trigger => {
        const targetId = trigger.getAttribute('data-popover-target');
        const target = document.getElementById(targetId);
        if (target && !trigger.hasAttribute('data-popover-initialized')) {
            const popover = new Popover(target, trigger);
            trigger.setAttribute('data-popover-initialized', 'true');
        }
    });
}

window.showToast = showToast;
window.initDatepickers = initDatepickers;
window.initLazyLoading = initLazyLoading;
// window.ApexCharts = ApexCharts;
document.addEventListener('DOMContentLoaded', () => {
    initLazyLoading();
    initializePopovers();
    initDatepickers();
    loadNotificationsModule();
});
